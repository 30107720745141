<template>
  <div>
    <div class="row mb-5">
      <div class="col-md-2 text-center flex-lg-fill my-1">
        <div class="d-flex flex-column text-dark-75">
          <span class="text-muted font-size-sm">{{ $t('label.status') }}</span>
          <span
            class="font-weight-bolder font-size-h5"
            :class="details.status === 1 ? 'text-success' : (details.status === 2 ? 'text-danger': 'text-warning')"
          >
            {{ details.status === 1 ? $t('label.active') : (details.status === 2 ? $t('label.passive') : $t('label.paused')) }}
          </span>
        </div>
      </div>
      <div class="col-md-2 text-center flex-lg-fill my-1">
        <div class="d-flex flex-column text-dark-75">
          <span class="text-muted font-size-sm">{{ $t('label.monthly_tariff') }}</span>
          <span class="font-weight-bolder font-size-h5">{{ details.cost }}</span>
        </div>
      </div>
      <div class="col-md-2 text-center flex-lg-fill my-1">
        <div class="d-flex flex-column text-dark-75">
          <semi-circle-progress
            :percentage="daysLeft"
            :stroke-width="15"
            stroke-bg-color="#B5B5C3"
            :stroke-color="details.daysLeft < 20 ? '#F64E60' : '#1BC5BD'"
          >
            <template #default>
              {{ details.daysLeft }}
            </template>
          </semi-circle-progress>
          <span class="text-muted font-size-sm">{{ $t('label.day') }}</span>
        </div>
      </div>
      <div class="col-md-2 text-center flex-lg-fill my-1">
        <div class="d-flex flex-column text-dark-75">
          <span class="text-muted font-size-sm">{{ $t('label.disabled_date') }}</span>
          <span class="font-weight-bolder font-size-h5">{{ details.disabledDate }}</span>
        </div>
      </div>
      <div class="col-md-2 text-center flex-lg-fill my-1">
        <button type="button" class="btn btn-light-warning" @click="$emit('showPackagesModal')">
          {{ $t('btn.change_package') }}
        </button>
      </div>
      <div v-if="actionName" class="col-md-2 text-center flex-lg-fill my-1">
        <button type="button" class="btn btn-light-danger" @click="$emit('toggleSubscription')">
          {{ $t(`btn.${actionName}`) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: () => {} }
  },
  computed: {
    currentMonthDaysCount() {
      const now = new Date();
      const start = new Date(now.getFullYear(), now.getMonth(), 1);
      const end = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      return (end - start) / (1000 * 60 * 60 * 24);
    },
    daysLeft() {
      const maxScale = this.currentMonthDaysCount;
      return (this.details.daysLeft / maxScale) * 100;
    },
    details() {
      return this.data?.internetTv || {};
    },
    balance() {
      return this.details?.balance || {};
    },
    isStatusActive() {
      return this.details?.status === 1;
    },
    isStatusPause() {
      return this.details?.status === 3;
    },
    actionName() {
      let name = null;
      if (this.isStatusActive) name = 'pause';
      if (this.isStatusPause) name = 'resume';

      return name;
    }
  }
}
</script>

<style lang="scss">
.a-circle-progress-wrapper {
  display: flex;
  justify-content: center;

  .circle-progress {
    width: 70px;

    .progress-content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
    }
  }
}
</style>