<template>
  <div>
    <div v-if="data && data.internetTv">
      <HeaderData
        :data="data"
        @showPackagesModal="$emit('showPackagesModal')"
        @toggleSubscription="$emit('toggleSubscription')"
      />
      <div class="separator separator-solid my-5"/>
      <DownloadDataForm :apartment-id="apartmentId" :data="data"/>
    </div>
    <div v-else>
      <div class="lead text-center my-5">
        {{ $t('description.no_data') }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import HeaderData from '@/components/apartments/internet-tv/HeaderData';
import DownloadDataForm from '@/components/apartments/internet-tv/DownloadDataForm';

export default {
  components: {
    HeaderData,
    DownloadDataForm
  },
  props: {
    apartmentId: {default: null},
    data: {type: Object, default: () => {}}
  }
}
</script>