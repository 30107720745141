<template>
  <b-modal centered hide-header hide-footer id="pay-modal" ref="pay-modal">
    <div v-if="data" class="mb-5">
      <div class="text-center">{{ $t('label.water_code') }}</div>
      <div class="text-center mt-3">
      <span
          class="label label-inline label-rounded cursor-pointer label-light-success"
          :class="{'cursor-pointer': data.couter_id}"
          id="text-to-copy"
          @click="copyText(data.counter_id)"
      >
        {{ data.counter_id || '-' }}
      </span>
      </div>
      <div class="text-center">
        <button class="btn btn-success btn-sm border-0 mt-3" @click="copyText(data.counter_id)">
          {{ $t('btn.copy_to_clipboard') }}
        </button>
      </div>
    </div>

    <div class="embed-responsive embed-responsive-16by9">
      <video autoplay muted loop="true">
        <source src="/media/water.webm" type="video/webm">
      </video>
    </div>

    <div class="d-flex justify-content-center mt-5">
      <button type="button"
              class="btn btn-hover-bg-danger btn-text-danger btn-hover-text-white border-0 font-weight-bold mr-2"
              @click="hideModal"
      >
        {{ $t('btn.cancel') }}
      </button>
      <button type="button"
              class="btn btn-hover-bg-success btn-text-success btn-hover-text-white border-0 font-weight-bold mr-2"
              @click="submitModal"
      >
        {{ $t('btn.pay') }}
      </button>

      <b-tooltip ref="copyTooltip" target="text-to-copy" triggers="manual">
        {{ $t('description.text_copied') }}
      </b-tooltip>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    data: {
      type: Object, default: () => {
      }
    }
  },
  methods: {
    async copyText(text) {
      if (!text) return false;
      try {
        await navigator.clipboard.writeText(text);
        this.$refs.copyTooltip.$emit('open');
        setTimeout(() => {
          this.$refs.copyTooltip.$emit('close');
        }, 1500);
      } catch (error) {
        console.log({error});
      }
    },
    submitModal() {
      this.hideModal();
      this.$emit('actionConfirmed');
    },
    hideModal() {
      this.$refs['pay-modal'].hide();
    },
  }
}
</script>

<style lang="scss" scoped>
.img-wrapper {
  height: 350px;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
  }
}
</style>